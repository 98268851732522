var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filter-bar"},[_c('section',[_c('div',{staticClass:"filter-bar__wrapper"},[_c('div',{staticClass:"filter-bar__filters"},[_c('div',{staticClass:"filter-bar__filter__container"},_vm._l((_vm.filters),function({ values, open },name){return _c('div',{key:`category-filter-${name}`,staticClass:"filter-bar__filter",class:{ 'filter-bar__filter--open': open }},[_c('p',{on:{"click":function($event){return _vm.toggle_filter(name, !open)}}},[_vm._v(_vm._s(name))]),_c('div',{staticClass:"filter-bar__value__wrapper",class:{ 'filter-bar__value__wrapper--open': open }},_vm._l((values),function(value,index){return _c('div',{key:index,staticClass:"filter-bar__value",class:{
                  'filter-bar__value--selected': _vm.chosen_filters.some(item => item.value === value),
                  'filter-bar__value--is-color': _vm.is_value_a_color(value),
                },domProps:{"innerHTML":_vm._s(_vm.is_value_a_color(value) ? `<div style='background-color: ${value}'></div>` : value)},on:{"click":function($event){return _vm.toggle_filter_value(name, value)}}})}),0)])}),0),_c('div',{staticClass:"filter-bar__value__wrapper",class:{ 'filter-bar__value__wrapper--open': _vm.filter_in_use[1].open }},_vm._l((_vm.filter_in_use[1].values),function(value,index){return _c('div',{key:index,staticClass:"filter-bar__value",class:{
              'filter-bar__value--selected': _vm.chosen_filters.some(item => item.value === value),
              'filter-bar__value--is-color': _vm.is_value_a_color(value),
            },domProps:{"innerHTML":_vm._s(_vm.is_value_a_color(value) ? `<div style='background-color: ${value}'></div>` : value)},on:{"click":function($event){return _vm.toggle_filter_value(_vm.filter_in_use[0], value)}}})}),0),_c('div',{staticClass:"filter-bar__sorter"},[_c('img',{staticClass:"filter-bar__sorter__icon",attrs:{"src":`/static/icons/sort-price-${_vm.sort_price_up ? 'up' : 'down'}.svg`,"alt":"sorter icon"},on:{"click":() => { _vm.sort_price_up = !_vm.sort_price_up; _vm.$emit('sort_by_price', _vm.sort_price_up)}}}),_c('img',{staticClass:"filter-bar__sorter__icon",attrs:{"src":`/static/icons/sort-alpha-${_vm.sort_alpha_up ? 'up' : 'down'}.svg`,"alt":"sorter icon"},on:{"click":() => { _vm.sort_alpha_up = !_vm.sort_alpha_up; _vm.$emit('sort_alphabeticaly', _vm.sort_alpha_up)}}})])]),(Object.keys(_vm.filters).length)?_c('div',{staticClass:"filter-bar__chosen-values"},[_vm._l((_vm.chosen_filters),function({ value, name }){return _c('div',{key:`chosen-value-${value}`,staticClass:"filter-bar__chosen-value",class:{
            'filter-bar__value--is-color': _vm.is_value_a_color(value),
          },domProps:{"innerHTML":_vm._s(_vm.is_value_a_color(value) ? `<div style='background-color: ${value}'></div>` : value)},on:{"click":function($event){return _vm.toggle_filter_value(name, value)}}})}),(_vm.chosen_filters.length > 0)?_c('div',{staticClass:"filter-bar__clear-chosen",on:{"click":_vm.clear_all_filters}},[_vm._v(" "+_vm._s(_vm.translations.clear_filters || _vm.$translate("products.clear_filters"))+" ")]):_vm._e()],2):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }