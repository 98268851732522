<template>
  <div class="filter-bar">
    <section>
      <div class="filter-bar__wrapper">
        <div class="filter-bar__filters">
          <div  class="filter-bar__filter__container">

            <!---------- Filters ---------->

            <div 
              v-for="({ values, open }, name) in filters"
              :key="`category-filter-${name}`"
              class="filter-bar__filter"
              :class="{ 'filter-bar__filter--open': open }"
            >
              <p @click="toggle_filter(name, !open)">{{ name }}</p>
              <div 
                class="filter-bar__value__wrapper"
                :class="{ 'filter-bar__value__wrapper--open': open }"
              >
                <div
                  v-for="(value, index) in values"
                  :key="index"
                  class="filter-bar__value"
                  @click="toggle_filter_value(name, value)"
                  :class="{
                    'filter-bar__value--selected': chosen_filters.some(item => item.value === value),
                    'filter-bar__value--is-color': is_value_a_color(value),
                  }"
                  v-html="is_value_a_color(value) ? `<div style='background-color: ${value}'></div>` : value"
                />
              </div>
            </div>
          </div>

          <div
            class="filter-bar__value__wrapper"
            :class="{ 'filter-bar__value__wrapper--open': filter_in_use[1].open }"
          >
            <div
              v-for="(value, index) in filter_in_use[1].values"
              :key="index"
              class="filter-bar__value"
              @click="toggle_filter_value(filter_in_use[0], value)"
              :class="{
                'filter-bar__value--selected': chosen_filters.some(item => item.value === value),
                'filter-bar__value--is-color': is_value_a_color(value),
              }"
              v-html="is_value_a_color(value) ? `<div style='background-color: ${value}'></div>` : value"
            />
          </div>

          <!---------- Sorter ---------->

          <div class="filter-bar__sorter">
            <img 
              class="filter-bar__sorter__icon"
              :src="`/static/icons/sort-price-${sort_price_up ? 'up' : 'down'}.svg`" 
              alt="sorter icon"
              @click="() => { sort_price_up = !sort_price_up; $emit('sort_by_price', sort_price_up)}"
            /><img 
              class="filter-bar__sorter__icon"
              :src="`/static/icons/sort-alpha-${sort_alpha_up ? 'up' : 'down'}.svg`" 
              alt="sorter icon"
              @click="() => { sort_alpha_up = !sort_alpha_up; $emit('sort_alphabeticaly', sort_alpha_up)}"
            />
          </div>
        </div>

        <!---------- Filter values ---------->

        <div 
          v-if="Object.keys(filters).length"
          class="filter-bar__chosen-values"
        >
          <div 
            v-for="{ value, name } in chosen_filters"
            :key="`chosen-value-${value}`"
            class="filter-bar__chosen-value"
            :class="{
              'filter-bar__value--is-color': is_value_a_color(value),
            }"
            @click="toggle_filter_value(name, value)"
            v-html="is_value_a_color(value) ? `<div style='background-color: ${value}'></div>` : value"
          />
          <div
            v-if="chosen_filters.length > 0"
            class="filter-bar__clear-chosen"
            @click="clear_all_filters"
          >
            {{ translations.clear_filters || $translate("products.clear_filters") }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex"
import get_all_categories from "../../../Client/methods/get_all_categories"
import { CLIENT_STORE } from "../../../Client/constants/other"
import { default_option_title } from "../../../Admin/constants/others_constants"
import is_value_a_color from "../../methods/is_value_a_color"
import get_correct_translation_value from '../../methods/get_correct_translation_value'

export default {
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      sort_price_up: false,
      sort_alpha_up: false,
      chosen_sorter: "",
      filters: {},
      chosen_filters: [],
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "translations",
      "selected_language"
    ]),
    filter_in_use() {
      const filters = Object.entries(this.filters)

      // eslint-disable-next-line no-unused-vars
      return filters.length ? (filters.find(([_, { open }]) => open) || ["", {}]) : ["", {}]
    }
  },
  watch: {
    // Add filter settings to url
    chosen_filters(new_value) {
      const url = new URL(window.location.href)

      if (new_value.length > 0) {
        url.searchParams.delete("filters")

        const transformed_to_route_value = new_value.reduce(
          (total, { name, value }) => `${total === "" ? "" : `${total},`}${name}:${value}`, ""
        )
        url.searchParams.append("filters", transformed_to_route_value)
      }
      else url.searchParams.delete("filters")

      window.history.pushState({ path: url.href }, "", url.href)
    },
    $route() {
      this.update_filters()
      this.update_selected_values_from_url()
    },
    products() {
      this.update_filters()
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.update_filters()
      this.update_selected_values_from_url()
    })
  },
  methods: {
    get_all_categories,
    is_value_a_color,
    update_filters() {
      if (this.products.length && this.products.some(({ id }) => !id)) return

      let temp_filters = {}

      this.products
        .filter(({ id }) => id)
        .flatMap(({ options }) => options)
        .filter((option) => default_option_title !== get_correct_translation_value(option, "name", [this.selected_language]))
        .forEach((option) => {
          const correct_option = {
            name: get_correct_translation_value(option, "name", [this.selected_language]),
            values: get_correct_translation_value(option, "values", [this.selected_language])
          }

          if (temp_filters[correct_option.name]) {
            temp_filters[correct_option.name].values = [
              ...temp_filters[correct_option.name].values,
              ...correct_option.values
            ]
            temp_filters[correct_option.name].occurrences += 1
          } else {
            temp_filters[correct_option.name] = {
              values: correct_option.values || [],
              occurrences: 1,
            }
          }
        })

      temp_filters = Object
        .entries(temp_filters)
        .filter(([_, { occurrences }]) => occurrences > 1)
        .reduce((tot, [name, { values }]) => ({
          ...tot,
          [name]: {
            values: new Set(values),
            open: false
          }
        }), {})

      this.filters = temp_filters
    },
    /*
     * 
     */
    close_all_filters() {
      this.filters = Object.keys(this.filters).reduce((total, key) => ({
        ...total,
        [key]: {
          ...this.filters[key],
          open: false
        }
      }), {})
    },
    toggle_filter(name, open) {
      Object.keys(this.filters).forEach(key => this.filters[key].open = false)
      this.filters[name].open = open
    },
    /*
     * 
     */
    toggle_filter_value(filter, value) {
      this.chosen_filters = this.chosen_filters.some(item => item.value === value) ?
        this.chosen_filters.filter(item => item.value !== value) :
        [
          ...this.chosen_filters.filter(item => item.name !== filter),
          {
            value,
            name: filter
          }
        ]
      this.$emit("filter", this.chosen_filters)
    },
    /*
     * 
     */
    clear_all_filters() {
      this.chosen_filters = []
      this.$emit("filter", [])
    },
    update_selected_values_from_url() {
      if (this.$route.query.filters) {
        this.chosen_filters = Array.from(new Set(this.$route.query.filters.split(",")))
          .map(filter => filter.split(":"))
          .map(([name, value]) => ({ name, value }))

        this.$emit("filter", this.chosen_filters)
      }
    } 
  },
}
</script>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/_marketplace-constants" as *;

.filter-bar {
  $sorter-width: 60px;

  &__sorter {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $spacing--tiny;
    width: $sorter-width;
    height: 38px;
    vertical-align: top;
    transition: 500ms ease-out;

    &__icon {
      height: 100%;
      width: 100%;
      cursor: pointer;
    }
  }

  &__filter {
    position: relative;
    display: inline-block;
    vertical-align: top;
    zoom: 1;

    &::after {
      position: absolute;
      top: 14px;
      right: 6px;
      line-height: $font-size--tiny;
      font-size: $font-size--tiny;
      content: "▼";
      transition: $default-transition;
    }

    &--open {
      &::after {
        transform: rotate(180deg);
      }
    }

    &s {
      position: relative;
      margin-bottom: $spacing--tiny;
      background-color: var(--body_color);

      @media (max-width: $tablet) {
        margin-bottom: 0;
      }
    }

    &__container {
      position: relative;
      display: inline-block;
      width: calc(100% - #{$sorter-width} - 5px);
      transition: left 500ms ease-out;

      @media (max-width: $tablet) {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
      }
    }

    p {
      position: relative;
      display: block;
      width: 150px;
      padding: 10px $spacing--small 10px 5px;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--font__heavy);
      opacity: 1;
      cursor: pointer;
      box-sizing: border-box;
      transition: opacity 300ms linear;
      font-weight: 600;

      @media (max-width: $tablet) {
        width: 120px;
      }
    }

    .filter-bar__value__wrapper {
      display: none;

      @media (min-width: $tablet) {
        display: block;
      }
    }
  }

  &__value {
    position: relative;
    padding: $spacing--tiny $spacing--medium $spacing--tiny $spacing--tiny;
    border-bottom: 1px solid var(--background_heavy);
    text-align: left;
    transition: padding $default-transition;
    cursor: pointer;
    font-size: 14px;

    @media (max-width: $tablet) {
      padding: $spacing--small $spacing--medium $spacing--small $spacing--small;
    }

    &:hover {
      padding-left: 20px;
    }

    &:after {
      position: absolute;
      top: 11px;
      right: 10px;
      width: 13px;
      height: 13px;
      content: "";
      background-image: url("/static/icons/check-mark-grey.svg");
      background-repeat: no-repeat;
      background-size: cover;
      transition: $default-transition;

      @media (max-width: $tablet) {
        top: $spacing--small;
        right: $spacing--small;
        width: $tiny-icon;
        height: $tiny-icon;
      }
    }

    &--is-color {
      div {
        width: $tiny-icon;
        height: $tiny-icon;
        border: 1px solid $pure-black;
      }
    }

    &--selected {
      &:after {
        background-image: url("/static/icons/check-mark-green.svg");
      }
    }

    &__wrapper {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: 0;
      opacity: 0;
      z-index: -1;
      display: none;

      @media (max-width: $tablet) {
        display: block;
      }

      &--open {
        max-height: 200px;
        animation: drop-down-filter 400ms ease-out;
        overflow-y: auto;
        z-index: 5;
        opacity: 1;
        background-color: var(--body_color);
        box-shadow: 0 9px 9px 0 $shadow-color;
        border-top: 1px solid var(--background_heavy);
      }
    }
  }

  &__chosen-value {
    position: relative;
    display: inline-block;
    padding: 8.5px $spacing--small 8.5px $spacing--medium;
    margin-right: 5px;
    line-height: 15px;
    color: $pure-black;
    background-color: $pure-white;
    cursor: pointer;
    font-size: $font-size--medium;
    animation: appear-in 400ms ease-out;
    border-radius: $border-radius;
    vertical-align: top;
    box-shadow: 0 1px 12px -4px $shadow-color;

    &s {
      min-height: 33px;
    }

    &:after {
      position: absolute;
      top: 11px;
      left: 11px;
      width: 10px;
      height: 10px;
      content: "";
      background-image: url("/static/icons/close-red.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &__clear-chosen {
    display: inline-block;
    padding: 7px $font-size--tiny;
    background-color: $alert-color;
    color: $pure-white;
    font-size: $font-size--medium;
    cursor: pointer;
    border-radius: $border-radius;
  }
}

@keyframes pulse-forward-right {
  80% {
     transform: translateX(0);
  }
  85% {
     transform: translateX(5px);
  }
  90% {
     transform: translateX(0);
  }
  95% {
     transform: translateX(5px);
  }
  100% {
     transform: translateX(0);
  }
}

@keyframes pulse-forward-left {
  80% {
     transform: rotate(180deg);
  }
  85% {
     transform: rotate(180deg) translateX(5px);
  }
  90% {
     transform: rotate(180deg);
  }
  95% {
     transform: rotate(180deg) translateX(5px);
  }
  100% {
     transform: rotate(180deg);
  }
}

@keyframes appear-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes drop-down-filter {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 200px;
    opacity: 1;
  }
}
</style>
