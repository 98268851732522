<template>
  <section class="marketplace-products">
    <div class="marketplace-products__top-bar">
      <div class="marketplace-products__breadcrumbs">
        <div v-if="is_popular_products_page">{{ $translate("products.popular_products") }}</div>
        <div
          v-else
          v-for="(category_id, index) in breadcrumbs"
          :key="category_id"
          :class="{ 'element-loading': loading_products || !categories[category_id] }"
        >
          <router-link :to="get_category_route(index)">
            {{ categories[category_id] || category_id }}
          </router-link>
        </div>
      </div>
      <language_selector
        :translations="selected_product ? (selected_product.variants[0] || {}).translations : shared_variant_translations"
        @language_changed="language => selected_language = language"
      />
    </div>
    <div v-if="!is_product_selected">
      <filter_bar
        v-if="!is_popular_products_page"
        :products="get_products"
        @sort_by_price="direction => sort(direction, 'price')"
        @sort_alphabeticaly="direction => sort(direction, 'alphabeticaly')"
        @filter="updated_filters => filters = updated_filters"
      />
      <Product_detail
        v-for="product in loading_products ? [{},{},{},{},{}] : get_filtered_products"
        :key="product.id"
        :product_data="product || {}"
        :loading="loading_products"
        :selected_language="selected_language"
        :show_detail="false"
      />
    </div>
    <Product_detail
      v-if="is_product_selected"
      :product_data="selected_product || {}"
      :loading="loading_products"
      :show_detail="true"
      :selected_language="selected_language"
      :class="{ 'marketplace-products__open-product': is_product_selected }"
    />
    <p
      class="marketplace-products__message"
      v-if="!loading_products && (is_product_selected ? !selected_product : !get_products.length)"
    >
      {{ $translate("products.no_products_message") }}
    </p>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { get_marketplace_breadcrumbs } from "../../constants/endpoints";
import { MARKETPLACE_PRODUCTS_STORE, POPULAR_PRODUCTS } from "../../constants/other";
import { LOAD_PRODUCTS, UPDATE_CATEGORIES, UPDATE_SELECTED_PRODUCT } from "../../stores/products/constants";
import Product_detail from "../utils/Product_detail"
import filter_bar from "../../../Shared/components/utils/filter_bar"
import language_selector from "../utils/language_selector"

export default {
  components: {
    Product_detail,
    filter_bar,
    language_selector
  },
  data() {
    return {
      popular_products: [{},{},{},{},{}],
      loading_products: true,
      sort_price: null,
      sort_alphabeticaly: null,
      selected_language: null,
      filters: [],
    }
  },
  computed: {
    ...mapState(MARKETPLACE_PRODUCTS_STORE, [
      "products",
      "selected_product",
      "categories"
    ]),
    get_products() {
      return Object.values(this.products)
    },
    get_category_ids() {
      return this.$route.params.pathMatch.split("/")
    },
    is_product_selected() {
      return this.get_category_ids[this.get_category_ids.length - 1].includes("-")
    },
    get_product_route() {
      return this.get_category_ids.join("_")
    },
    is_popular_products() {
      return this.get_category_ids[0] === POPULAR_PRODUCTS
    },
    breadcrumbs() {
      return this.is_product_selected ?
        this.get_category_ids.slice(0, this.get_category_ids.length - 1) : this.get_category_ids
    },
    shared_variant_translations() {
      if (!this.get_filtered_products.length) return
      const first_variants_for_each_product = this.get_filtered_products.map(({ variants }) => variants[0])
      const shared_languages_obj = {}

      first_variants_for_each_product.forEach(({ translations }) => {
        if (!translations) return

        const translations_with_price = Object.entries(translations).filter(([_, { price }]) => price).map(([key, _]) => key)
        translations_with_price.forEach(language_code =>
          first_variants_for_each_product.every(({ translations }) => translations && translations[language_code] && translations[language_code].price) ?
            shared_languages_obj[language_code] = "" : null
        )
      })

      return shared_languages_obj
    },
    get_filtered_products() {
      return this.get_products
        .filter(this.apply_filters)
        .sort((a, b) => {
          if (this.sort_alphabeticaly === null) return
          if (a.title < b.title) return this.sort_alphabeticaly ? 1 : -1
          if (a.title > b.title) return this.sort_alphabeticaly ? -1 : 1
          return 0
        })
        .sort((a, b) => {
          if (this.sort_price === null) return
          const a_price = Number(a.variants[0].price)
          const b_price = Number(b.variants[0].price)
          return this.sort_price ? (a_price - b_price) : (b_price - a_price)
        })
    },
    is_popular_products_page() {
      return this.$route.path.includes(POPULAR_PRODUCTS)
    }
  },
  watch: {
    $route: {
      async handler() {
        this.update_selected_product()
        this.load_product_or_category()
        this.load_remaining_breadcrumbs()
      },
      deep: true
    },
  },
  mounted() {
    this.update_selected_product()
    this.load_product_or_category()
    this.load_remaining_breadcrumbs()
  },
  methods: {
    ...mapMutations(MARKETPLACE_PRODUCTS_STORE, {
      update_categories: UPDATE_CATEGORIES,
      update_selected_product: UPDATE_SELECTED_PRODUCT
    }),
    ...mapActions(MARKETPLACE_PRODUCTS_STORE, {
      load_products: LOAD_PRODUCTS
    }),
    async load_remaining_breadcrumbs() {
      if (this.is_popular_products_page) return
      const { data } = await get_marketplace_breadcrumbs({ routes: this.breadcrumbs })

      this.update_categories({
        ...this.categories,
        ...data
      })
    },
    get_category_route(index) {
      return `/products/${this.get_category_ids.slice(0, index + 1).join("/")}`
    },
    apply_filters({ options }) {
      return this.filters.length ? this.filters.reduce((final_decision, { name, value }) =>
        final_decision ?
          options.find(({ name: option_name, values }) => name === option_name && values.includes(value)) : false
      , true) : true
    },
    sort(value, name) {
      this.sort_price = null
      this.sort_alphabeticaly = null
      this[`sort_${name}`] = value
    },
    async load_product_or_category() {
      this.loading_products = true
      await this.load_products([this.is_product_selected ? this.get_product_route : this.get_category_ids, this.is_popular_products])
      this.loading_products = false
    },
  }
};
</script>

<style lang="scss" scoped>
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/_marketplace-constants" as *;

.marketplace-products {
  padding: 0 0 $spacing--medium;

  @media (max-width: $tablet) {
    padding: 0 0 $spacing--small;
  }

  &__open-product {
    padding-top: $spacing--tiny;
  }

  &__message {
    margin: $spacing--large 0;
    text-align: center;
    font-size: $font-size--very-large;
  }

  &__breadcrumbs {
    position: relative;
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;

    div {
      position: relative;
      display: inline-block;
      margin-right: $spacing--small;
      vertical-align: top;

      &.element-loading {
        width: 100px;
        height: 17px;
        transform: translateY(1px);
      }

      &:hover {
        text-decoration: underline;
      }

      &::after {
        position: absolute;
        top: 0;
        right: -9px;
        height: 100%;
        width: 5px;
        content: "/";
      }

      &:last-child {
        font-weight: bold;

        &::after {
          display: none;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__top-bar {
    display: grid;
    grid-template-columns: calc(100% - 250px - #{$spacing--tiny}) 250px;
    column-gap: $spacing--tiny;
    padding: $spacing--small 0;
    border-bottom: 1px solid $grey--light;
    margin-bottom: $spacing--tiny;
    font-size: 15px;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;

    @media (max-width: $maximum-width) {
      padding: $spacing--small;
    }

    @media (max-width: $tablet--small) {
      grid-template-columns: calc(100% - #{$medium-small-icon} - #{$spacing--tiny}) #{$medium-small-icon};
    }
  }
}
</style>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/_marketplace-constants" as *;

.marketplace-products {
  .filter-bar {
    @media (max-width: $maximum-width) {
      padding: 0 $spacing--small;
    }
  }
}
</style>
