import has_product_stock from "./has_product_stock"

export default (router, products, is_shopify_website) => {
  let data = products[
    router.params.category
      ? router.params.category
      : window.default_main_page
  ]

  if(is_shopify_website) {
    return data
  }
  else {
    return Object.keys(data).reduce((total_products_array, key) => {
      const array_of_products = data[key]
      const number_of_items = array_of_products.length

      if(number_of_items === 1) {
        total_products_array = has_product_stock(array_of_products[0]) ?
          [
            ...array_of_products,
            ...total_products_array
          ] :
          [
            ...total_products_array,
            ...array_of_products
          ] 

      }
      else {
        array_of_products.forEach(item => {
          total_products_array = has_product_stock(item) ?
          [
            item,
            ...total_products_array,
          ] :
          [
            ...total_products_array,
            item
          ]
        }
        )
      }
      return total_products_array
    },[])
  }
}
